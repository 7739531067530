import * as React from "react"
import { graphql } from "gatsby"
import type { PageProps } from "gatsby"
import Layout from "../components/layout"
import PostList from "../components/postlist"
import type { MDXListType } from "../interfaces"


const IndexPage = ({ data }: PageProps<MDXListType>) => {
  return (
    <Layout pageTitle="Welcome">
      <p>
        🏠 I am a Software Developer in India with 6+ years of experience
        <br />
        🏢 Currently working as a Product Engineering Lead
        <br />
        🧡 History, Epic Fantasy, RPG & OSS. I try to 🎨✍️🎙️occasionally
        <br />
        🗣️ Open for Chats, Rants & Chants
      </p>
      <h2>Recent Posts</h2>
      <PostList posts={data.allMdx.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 10) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default IndexPage
